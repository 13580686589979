import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[lng]/(components)/Aliados.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[lng]/(components)/Certificaciones.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[lng]/(components)/Clientes.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[lng]/(components)/Contacto.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[lng]/(components)/Especializacion.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[lng]/(components)/Hero.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[lng]/(components)/Responsabilidad.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[lng]/(components)/Soluciones.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[lng]/(components)/SomosIngenieria.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[lng]/(components)/WAClick.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/SVG/PowerLine.js");
